<template>
  <div class="p-4 bg-white rounded-lg custom-container-height">
    <div class="mb-10 mx-4 mt-6 d-flex align-center justify-space-between">
      <datatable-detail :table_properties="tableProperties"></datatable-detail>
      <div v-if="hasExport" class="mr-4">
        <button @click="handleExport" class="btn btn--export-filter">
          <span class="svg-icon">
            <v-icon size="18">mdi-export-variant</v-icon>
          </span>
          Export
        </button>
        <Export
          ref="export"
          :headers="exportHeaders"
          :downloadItem="downloadItem"
          class="d-none"
        ></Export>
      </div>
    </div>

    <div class="mx-2">
      <Filters
        ref="filter"
        :submitFilterValues="submitFilterValues"
        :resetFilterValues="resetFilterValues"
        :filters="filter"
        :setTableFiltersValues="setTableFiltersValues"
        :hasEmail="hasEmail"
        :name="name"
        :table_properties="tableProperties"
        :pageLoader="pageLoader"
      ></Filters>
    </div>
    <template v-if="filtersResult">
      <v-data-table
        fixed-header
        light
        dense
        :items="items"
        :headers="headers"
        :sort-by.sync="orderBy"
        :sort-desc.sync="sortOrder"
        :items-per-page="itemPerPage"
        item-key="id.id"
        class="m-2 mt-4 px-6 font-weight-bold elevation-1"
        item-class="headerZindex"
        hide-default-footer
        mobile-breakpoint="300"
      >
        <!--    no-data-->
        <template v-slot:no-data> NO DATA HERE!</template>
        <template v-slot:no-results> NO RESULTS HERE!</template>
        <!--    no-data end-->
        <template
          v-for="(head, i) in headers"
          v-slot:[`item.`+head.value]="{ item, header, value }"
        >
          <span class="second-text" :key="i">{{
            value === "-" ? "" : value
          }}</span>
        </template>

        <template #item.Warehouse="{ value }">
          <span class="first-text">{{ value === "-" ? "" : value }}</span>
        </template>
        <template #item.Client="{ value }">
          <span class="first-text">{{ value }}</span>
        </template>
        <template #item.Status="{ value }">
          <StatusColumn
            class="d-flex justify-center align-content-start min-w-90px"
            :value="value"
          ></StatusColumn>
        </template>
        <template #item.WithinSLA="{ value }">
          <StatusColumn
            class="d-flex justify-center align-content-start w-90px"
            :value="value"
          ></StatusColumn>
        </template>
        <template #item.%ofMovement="{ value }">
          <v-icon class="text-success" v-if="value > 0">mdi-arrow-up</v-icon>
          <v-icon class="text-danger" v-else-if="value < 0"
            >mdi-arrow-down
          </v-icon>
          <span> {{ Math.abs(value) }}% </span>
        </template>
      </v-data-table>
      <Pagination
        :setitemPerPage="setItemPerPage"
        :getitemPerPage="getItemPerPage"
        :getpageNumber="getPageNumber"
        :setpageNumber="setPageNumber"
        :totalItems="totalItems"
        :pageCount="pageCount"
        :updateTableContent="updateTableContent"
      ></Pagination>
    </template>
  </div>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Filters from "@/own/components/filter/Filters.vue";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import Pagination from "@/own/components/pagination/Pagination.vue";
import Swal from "sweetalert2";
import Export from "@/own/components/Export.vue";
import StatusColumn from "@/own/components/datatable/StatusColumn";
import {
  EXPORT_REPORT_AGENT_DATA,
  GET_REPORT_AGENT_DATA,
  SET_REPORT_AGENT_FILTERS_RESULT,
  SET_TABLE_PAGE,
  SET_TABLE_PER_PAGE,
  SET_TABLE_SORT_BY,
  SET_TABLE_SORT_ORDER,
  SHOW_REPORT_AGENT_DATA,
} from "@/core/services/store/reportAgent.module";

export default {
  name: "ReportAgent",
  props: {
    /**
     * @description urls to fetch chart data
     * @type {{data_url: string, show_url: string}}
     */
    urls: {
      type: Object,
      default: () => ({ data_url: String, show_url: String }),
      required: true,
    },
    /**
     * @description for setting filters mandatory status
     * @values true, false, ['some_filter_name']
     * @type {boolean, [{title: string, name: string, valueFieldName: string}]}
     */
    mandatoryFilter: {
      type: [Boolean, Array],
      default: false,
    },
  },
  components: {
    Export,
    Filters,
    DatatableDetail,
    Pagination,
    StatusColumn,
  },
  beforeMount() {
    this.$store.dispatch(GET_REPORT_AGENT_DATA, this.urls.data_url);
    this.$store.commit(SET_REPORT_AGENT_FILTERS_RESULT, null);
  },
  data: () => ({
    filtersData: [],
  }),
  computed: {
    //pagination
    getItemPerPage: function () {
      return this.$store.getters.getREPORTAGENTTableState.per_page;
    },
    getPageNumber: function () {
      return this.$store.getters.getREPORTAGENTTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getREPORTAGENTFiltersResult.body.length;
    },
    pageCount: function () {
      return Math.ceil(this.totalItems / this.itemPerPage);
    },
    tableProperties: function () {
      return this.$store.getters.getREPORTAGENTProperties;
    },
    itemPerPage: function () {
      return this.$store.getters.getREPORTAGENTTableState.per_page;
    },
    orderBy: {
      get: function () {
        return this.$store.getters.getREPORTAGENTTableState.sortBy;
      },
      set: function (newValue) {
        if (newValue) {
          this.$store.commit(SET_TABLE_SORT_BY, newValue);
        } else {
          this.$store.commit(SET_TABLE_SORT_BY, "");
        }
      },
    },
    sortOrder: {
      get: function () {
        let sortOrder = true;
        if (this.$store.getters.getREPORTAGENTTableState.sortOrder === "asc") {
          sortOrder = false;
        }
        return sortOrder;
      },
      set: function (newValue) {
        if (newValue === true) {
          this.$store.commit(SET_TABLE_SORT_ORDER, "desc");
        } else {
          this.$store.commit(SET_TABLE_SORT_ORDER, "asc");
        }
      },
    },
    headers: function () {
      let headers = this.$store.getters.getREPORTAGENTFiltersResult.header;
      return headers.map((header) => {
        return {
          text: header,
          value: header.replaceAll(" ", ""),
          class:
            "py-4 text-muted font-size-sm fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        };
      });
    },
    items: function () {
      let body = this.$store.getters.getREPORTAGENTFiltersResult.body;
      return body.map((items) => {
        let obj = {};
        items.forEach((item, index) => {
          obj[`${this.headers[index].value}`] = item;
          return obj;
        });
        return obj;
      });
    },
    hasExport: function () {
      return this.$store.getters.getREPORTAGENTHasExport;
    },
    exportHeaders: function () {
      let headers = [],
        counter = 0;
      this.hasExport &&
        this.filter.forEach((item) => {
          const obj = {
            order: counter++,
            text: item.title,
            value: item.name,
          };
          headers.push(obj);
        });
      return headers;
    },
    filter: function () {
      return this.$store.getters.getREPORTAGENTFilters;
    },
    filtersResult: function () {
      return this.$store.getters.getREPORTAGENTFiltersResult;
    },
    hasEmail: function () {
      return this.tableProperties.sent_by_email;
      // return true;
    },
    name: function () {
      const match = this.urls.data_url.match(/\/reports\/(.+)/);
      return match ? match[1] : null;
    },
  },
  watch: {
    exportHeaders: function () {
      this.$nextTick(() => {
        this.$refs.export.$data.columns_to_export = this.exportHeaders;
        this.$refs.export.$data.columns_to_export.forEach((col, index) => {
          this.$refs.export.$data.columns_to_export[index] = {
            exportable: true,
            ...col,
          };
        });
      });
    },
  },
  methods: {
    setItemPerPage(val) {
      this.$store.commit(SET_TABLE_PER_PAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setPageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    updateTableContent() {
      this.$forceUpdate();
    },
    handleExport() {
      if (this.isAnyFilterSelected()) {
        this.$refs.export.exportColumns();
      } else {
        Swal.fire({
          title: "Warning",
          text: `Please, select at least one column to export!`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    /**
     * validate requirement of filters
     * @returns Boolean
     */
    validateFilterValues() {
      let is_valid = true;
      // if mandatoryFilter array then checks are all filters located in array are selected
      if (Array.isArray(this.mandatoryFilter)) {
        this.mandatoryFilter.forEach((filter) => {
          let is_exist = false;
          this.filtersData.forEach((data) => {
            if (data.name === filter.name + filter.valueFieldName) {
              if (
                data.value !== "" &&
                data.value !== null &&
                data.value !== undefined
              ) {
                is_exist = true;
              }
            }
          });
          if (!is_exist) {
            is_valid = false;
          }
        });
      }
      // if mandatoryFilter is true then check all filter values are selected
      else if (this.mandatoryFilter) {
        this.filters.forEach((filter) => {
          let is_exist = false;
          this.filtersData.forEach((data) => {
            if (data.name === filter.name) {
              is_exist = true;
            }
          });
          if (!is_exist) {
            is_valid = false;
          }
        });
      }
      return is_valid;
    },
    /**
     * validate filters and set chart filter then and reload data from server
     */
    submitFilterValues() {
      if (this.filtersData && this.validateFilterValues()) {
        this.$store.dispatch(SHOW_REPORT_AGENT_DATA, {
          url: this.urls.show_url,
          filtersData: this.filtersData,
        });
      } else {
        if (Array.isArray(this.mandatoryFilter)) {
          let message = this.mandatoryFilter
            .map((filter) => filter.title)
            .join(",");
          Swal.fire(
            "Warning!",
            `Select ${message} filter${
              this.mandatoryFilter.length > 1 ? "s" : ""
            }!`,
            "warning"
          );
        } else if (this.mandatoryFilter) {
          Swal.fire("Warning!", "Select all filters!", "warning");
        }
      }
    },
    setTableFiltersValues(val) {
      this.filtersData = val;
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$refs.filter.$refs.filter.forEach((filter) => {
        filter.clearData();
      });
      this.filtersData = [];
      this.$store.dispatch(GET_REPORT_AGENT_DATA, this.urls.data_url);
      this.$store.commit(SET_REPORT_AGENT_FILTERS_RESULT, null);
      this.$store.commit(SET_PAGE_LOADING, false);
    },
    isAnyFilterSelected() {
      return this.filtersData.length > 0;
    },
    downloadItem(columns) {
      if (this.isAnyFilterSelected()) {
        this.$store.commit(SET_PAGE_LOADING, true);
        const payload = {
          columns: columns,
          filters: this.filtersData,
        };
        this.$store
          .dispatch(EXPORT_REPORT_AGENT_DATA, {
            dataUrl: this.urls.data_url,
            payload,
          })
          .then(() => {
            if (this.$store.getters.getREPORTAGENTExportUrl) {
              const link = document.createElement("a");
              link.href = this.$store.getters.getREPORTAGENTExportUrl;
              link.download = "Export.csv";
              link.click();
              link.remove();
            }
            setTimeout(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            }, 1500);
            this.$refs.export.toggleModal();
          });
      } else
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one filter!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
    },
    pageLoader(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
  },
};
</script>

<style scoped>
.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 171px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 141px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 126px) !important;
  }
}
</style>
