<template>
  <ReportAgent
    :urls="urls"
    :mandatory-filter="[
      {
        name: 'date_range',
        title: 'Date range',
        valueFieldName: '.select',
      },
    ]"
  />
</template>

<script>
import ReportAgent from "@/own/components/reports/ReportAgent.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "ReportCallAgent",
  components: { ReportAgent },
  data: () => ({
    urls: {
      data_url: "/reports/reportCallAgent",
      show_url: "/reports/reportCallAgent/show",
    },
  }),

  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
};
</script>
